import React from "react";

import {
  Box,
  Button,
  Typography,
  Grid
  // Customizable Area Start
  
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import {neurology,Horn,Bulb,Home, note_stack,calendar_month,Spacks,graph, filter, question, summarize, calender2, tasknote } from "./assets";
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import ScheduleIcon from '@material-ui/icons/Schedule';
import PeopleOutlineOutlinedIcon from '@material-ui/icons/PeopleOutlineOutlined';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import AddIcon from '@material-ui/icons/Add';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import Footer from "./Footer.web";
import "./Header.css";
// Customizable Area End

import Catalogue1Controller, {
  Props,
} from "./Catalogue1Controller";
import Header from "./Header.web";

export default class Catalogue1 extends Catalogue1Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.updateGlow=this.updateGlow.bind(this)
    // Customizable Area End
  }

  // Customizable Area Start
 
  updateGlow = (rotate:number, color:string) => {
    this.setState({
      glowParams: {
        rotate,
        color
      }
    });
  };

  getBackground = () => {
    const { color } = this.state.glowParams;
    return `radial-gradient(
      20.95% 20.95% at 50.16% 20.95%,
      #ffffff 0%,
      ${color} 30%,
      #0f172a00 130%
    )`;
  };

 
    // Function to handle previous button click
    handlePrevClick = () => {
      const { currentDate} = this.state;
      return(
        this.setState({
          currentDate: new Date(currentDate.setDate(currentDate.getDate() - 7))
        })
      )
     
    };

    // Function to handle next button click
    handleNextClick = () => {
      const { currentDate} = this.state;
      return(
        this.setState({
          currentDate: new Date(currentDate.setDate(currentDate.getDate() + 7))
        })
        )
      };
      displayMonth=()=>{
        const { currentDate} = this.state;
        const currentMonth = currentDate.getMonth();
        const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        const currentMonthName = monthNames[currentMonth];
        return currentMonthName;
      }

     displayCalender= ()=> {
      const days = [ 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa','Su'];
          // Function to get the date for the given day index
      const getDateForDay = (dayIndex:number) => {
          const { currentDate} = this.state;
          const tempDate = new Date(currentDate);
          tempDate.setDate(tempDate.getDate() + dayIndex);
          const day = String(tempDate.getDate()).padStart(2, '0');
          return day;
        };

      return(
        <div className="calenderStyle">
          <Button data-test-id="calenderLeftBtn" style={{minWidth:'20px'}} onClick={() => this.handlePrevClick()}>
            <KeyboardArrowLeftIcon style={{ backgroundColor: '#1E293B', color: 'white' }} />
          </Button>
          <div style={{ display: 'flex', color: 'white', fontFamily: 'Raleway', fontWeight: 400,justifyContent:'space-evenly' }}>
            {days.map((day, index) => (
              <div key={index} style={{ flex: 1, textAlign: 'center' }}>
                <div style={{ marginLeft: 10, fontSize: '14px',fontFamily:'poppins' }}>{day}</div>
                <div style={{ ...(this.state.selectedDate==index ? webStyle.selectDateColor : null), fontFamily: 'poppins', fontSize: '14px',cursor:'pointer',marginLeft: 10,marginTop:5}} onClick={()=>this.setState({selectedDate:index})}>{getDateForDay((index))}</div>
              </div>
            ))}
          </div>
          <Button data-test-id="calenderRightBtn" style={{minWidth:'20px'}} onClick={() => this.handleNextClick()}>
            <KeyboardArrowRightIcon style={{ backgroundColor: '#1E293B', color: 'white' }} />
          </Button>
        </div>
      )
     }
     
  renderDotPagination() {
    const { createdTask, currentTaskPage, tasksPerPage } = this.state;
    const totalPages = Math.ceil(createdTask.length / tasksPerPage);

    return (
      <Box style={{ display: 'flex', justifyContent: 'center' }}>
        {Array.from({ length: totalPages }, (_, index) => (
          <Box
            key={index}
            onClick={() => this.handlePageChangeTask(index + 1)}
            style={{
              width: '10px',
              height: '10px',
              borderRadius: '50%',
              margin: '0 5px',
              cursor: 'pointer',
              backgroundColor: currentTaskPage === index + 1 ? '#FFFFFF' : 'rgba(255, 255, 255, 0.3)',
              transition: 'background-color 0.3s',
            }}
          />
        ))}
      </Box>
    );
  }  
  
  taskSelection = () => {
    const { glowParams, showMessage} = this.state;
    const { createdTask, currentTaskPage, tasksPerPage } = this.state;
    const startIndex = (currentTaskPage - 1) * tasksPerPage;
    const endIndex = startIndex + tasksPerPage;
    const currentTasks = createdTask.slice(startIndex, endIndex);
    return (
      <div className="taskContainer">        
          <h1 className="welcomeTxt">Welcome <b>{this.state.userName}</b></h1>
          <span className="welcomeBottom-border"></span>
        <div className="questionImg">
          <img src={question} style={webStyle.imgQ}/>
        </div>
        <div className='UpperContainer'>
          <Box className="CircleContainer">
          {glowParams.rotate !== -1 && (
            <div
              data-test-id="GlowBtn"
              className="Glow"
              style={{
                transform: `rotate(${glowParams.rotate}deg)`,
                background: this.getBackground()
              }}
            ></div>
          )}

            <Box className="backShadowFirst knowledgeCenter ">
              <div
                style={{
                  boxShadow:
                    this.state.currentGlow == "firstBox"
                      ? "rgba(114, 239, 221, 0.5) 0px 40px 50px 0px,rgba(114, 239, 221, 0.6) 0px 0px 10px"
                      : "rgba(114, 239, 221, 0.6) 0px 40px 50px 0px,rgba(114, 239, 221, 0.6) 0px 0px 10px",
                  opacity:
                    this.state.currentGlow == "firstBox" ? "2" : "1.9",
                }}
                data-test-id="firstButton"
                onMouseEnter={()=>this.setState({currentGlow:'firstBox'})}
                onMouseLeave={()=>this.setState({currentGlow:''})}
                className="firstBtn commonBox">
                <img src={neurology} alt="neurology" className="centerIconSize"/>
              </div>
              
              <Box className="hover-white-text" style={{ width: '89px', color: '#FFFFFF'  }}>
                <Typography style={{fontSize:'12px',fontWeight: 600,textAlign:'center'}}> Knowledge & Training Center</Typography>
              </Box>
            </Box>
            <Box className="positionSecond" >
              <div
                style={{
                  boxShadow:this.state.currentGlow=="secondBox"?"#B429F9 0px 40px 50px 0px,rgba(114, 239, 221, 0.6) 0px 0px 10px":'none'
              }}
                data-test-id="secondButton"
                onMouseEnter={()=>this.setState({currentGlow:'secondBox'})}
                onMouseLeave={()=>this.setState({currentGlow:''})}
               className="secondBtn commonBox"
              >
                <img src={Bulb} alt="Bulb"/>
              </div>
              <Box className="secondBtnTitle">
                <Typography style={{fontSize:'12px'}}> Healthcare Innovation & Entrepreneurship</Typography>
              </Box>
            </Box>
            <Box className="positionThird">
              <div 
               data-test-id="thirdButton"
            style={{
              boxShadow:this.state.currentGlow=="thirdBox"?'#26C5F3 0px 40px 50px 0px,rgba(114, 239, 221, 0.6) 0px 0px 10px':'none'
          }}
            onMouseEnter={()=>this.setState({currentGlow:'thirdBox'})}
            onMouseLeave={()=>this.setState({currentGlow:''})}
              className="thirdBtn commonBox"
             >
               <img src={Spacks} alt="Spacks"/>
              </div>
              <Box className="thirdBtnTitle" style={{  paddingRight: '100x'}}>
                  <Typography style={{ fontSize: '12px' }}>
                    Healthcare <br /> VR/AR & Gaming
                  </Typography>
              </Box>
            </Box>

            <Box className="positionFourth" >
              <div 
               data-test-id="fourthBtn"
               style={{
                boxShadow:this.state.currentGlow=="fourthBox"?'#2D00F7 0px 40px 50px 0px,rgba(114, 239, 221, 0.6) 0px 0px 10px':'none'
            }}
               onMouseEnter={()=>this.setState({currentGlow:'fourthBox'})}
               onMouseLeave={()=>this.setState({currentGlow:''})}
              // onMouseEnter={() => this.updateGlow(-135, "#2D00F7")}
              // onMouseLeave={() => this.updateGlow(-1,"yellow")}
              className="commonBox"
             >
                <img src={Horn} alt="Horn"/>
              </div>
              <Box style={{ width: '118px', textAlign: 'center',color: '#FFFFFF'  }}>
                <Typography style={{fontSize:'12px'}}>Healthcare Research</Typography>
              </Box>
            </Box>

            <Box className="positionFifth" >
              <div 
               data-test-id="fifthBtn"
               style={{
                boxShadow:this.state.currentGlow=="fifthBox"?'#5591F5 0px 40px 50px 0px,rgba(114, 239, 221, 0.6) 0px 0px 10px':'none'
            }}
               onMouseEnter={()=>this.setState({currentGlow:'fifthBox'})}
               onMouseLeave={()=>this.setState({currentGlow:''})}
               className="commonBox"
              >
               <img src={Home} alt="Home"/>
              </div>
              <Box style={{ width: '105px', textAlign: 'center' ,color: '#FFFFFF'  }}>
                <Typography style={{fontSize:'12px'}}> Marketplace</Typography>
              </Box>
            </Box>
          </Box>

          <Box
          data-test-id="myTask" 
          className={
            !this.state.addNewTask ? "newTask":
             "addNewTask"}>
            <Box style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
              <Typography style={{color:'white',fontWeight:700, fontSize: '20px'}}>My Tasks</Typography>
              <Box style={{ textTransform: 'capitalize', background: "rgba(255, 255, 255, 0.1)", borderRadius: '20px'
}}>
                <Button variant='outlined' style={{ borderRadius: '34px', textTransform: 'capitalize',fontWeight: 300, color: 'white' }}> today</Button>
              </Box>
            </Box>
            {!this.state.addNewTask?
            <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginTop: '98px',opacity:'30%',height:'112px',marginBottom: '75px'}}>
              <img src={note_stack} />
              <Box style={{ textTransform: 'capitalize', borderRadius: '34px' ,borderTop: 10}}>
                <Typography style={{fontWeight:700,fontSize:'14px',color:'rgba(255,255,255,1)'}}>Start fresh !</Typography>
              </Box>
              <Box style={{ textTransform: 'capitalize', borderRadius: '34px' }}>
                <Typography style={{fontWeight:300,fontSize:'14px',color:'rgba(255,255,255,1)'}}>Create your first task now</Typography>
              </Box>
            </Box>
            :
              <div data-test-id="createdTask" >
                {this.state.createdTask ?
                  <Box className="taskSection">

                    {currentTasks.map((task: any) => {
                    
                      return (
                        <Box className="createdTask">
                          <div className="titleTask">

                          <Box className= "taskImg">
                            <img src={tasknote}
                             className="task-img p-2"/>
                          </Box>
                          <span  className="task-title">
                            {task.attributes?.title}
                          </span>
                          </div>
                          <div className="titleTask">
                          <Box className= "taskImg">
                            <img src= {calender2}
                               className="task-img" />
                          </Box>
                          <span className="task-title taskdeadline">
                            {task.attributes?.deadline}
                          </span>
                          <span className="greenCircle"></span>
                          </div>
                          <span className="skipCircle"></span>
                        </Box>
                      )
                    })}
                   {this.renderDotPagination()}
                  </Box>
                  :
                  <Typography style={{ color: "white" ,flex:1,height: '276px',textAlign:'center',paddingTop:'50px',justifyContent:'center',alignItems:'center'}}>
                    {showMessage}
                  </Typography>
                }
              </div>
           
            }
            <Box style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>     
              <Box style={{ textTransform: 'capitalize', borderRadius: '34px', width: '86%', display: 'flex', justifyContent: 'center'  
             }}>
                <Button data-test-id="newTaskBtn" onClick={()=>this.onNewTask()} className='button-with-gradient-border' variant='outlined' style={{ borderRadius: '100px', width: '86%',height:'32px' ,padding:'2px',textTransform: 'capitalize',color: 'white', fontWeight: 800,background: 'linear-gradient(to right,  #6200EA 0%,#CBEBFF 53%,#26C5F3 100%)' ,overflow:'hidden'}}> 
                <span style={{width:'100%',height:'28px', backgroundColor:'#1E293B',borderRadius:'100px',display:'flex',alignItems:'center',justifyContent:'center'}}>
                New Task
                </span>
                </Button>
              </Box>
            </Box>

          </Box>

          <Box className="upcoming">
            <Box style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
              <Typography style={{color:'white',fontWeight:700, fontSize: '20px'}}>Upcoming</Typography>
              <Box style={{ textTransform: 'capitalize', background: "rgba(255, 255, 255, 0.1)",borderRadius: '20px'}}>
                <Button variant='outlined' style={{ borderRadius: '34px', textTransform: 'capitalize',fontWeight: 300, color: 'white'  }}> {this.displayMonth()}</Button>
              </Box>
            </Box>
             <div>
              {this.displayCalender()}
             </div>
             
            <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginTop: '57px',opacity: '30%' }}>

              <img src={calendar_month} />
              <Box style={{ textTransform: 'capitalize', borderRadius: '34px' }}>
                <Typography style={{fontWeight:700,fontSize:'14px',color:'rgba(255,255,255,1)'}}>Your calendar's empty!</Typography>
              </Box>
              <Box style={{ textTransform: 'capitalize', borderRadius: '34px' }}>
                <Typography style={{fontWeight:300,fontSize:'14px',color:'rgba(255,255,255,1)',textAlign: 'center'}}>Schedule lectures, meetings, and other learning opportunities.</Typography>
              </Box>
            </Box>
            <Box style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', margin: '67px 30px' }}>
              <Box style={{ textTransform: 'capitalize', borderRadius: '34px', width: '86%'
              
            }}>
               <Button className='button-with-gradient-border' variant='outlined' style={{ borderRadius: '100px', width: '91%',height:'32px' ,padding:'2px',textTransform: 'capitalize',color: 'white', fontWeight: 800,background: 'linear-gradient(to right,  #6200EA 0%,#CBEBFF 53%,#26C5F3 100%)' ,overflow:'hidden'}}> 
               <span style={{width:'100%',height:'28px', backgroundColor:'#1E293B',borderRadius:'100px',display:'flex',alignItems:'center',justifyContent:'center'}}>
               My Schedule
               </span>
               </Button>
             </Box>
              <div style={webStyle.plusBtnStyle}>
                <AddIcon/>
              </div>
            </Box>

          </Box>
        </div>
      </div>
    )
  }

  cardContend = ()=> {
    return(
      <Box className="cardMainContainer">
        <Typography style={{...webStyle.subHeadingText,padding:'15px 0px',marginLeft:'2%'}}>Recommended</Typography>
        <Box className="CradBox">
          {this.state.cardDetails.map((card: any,index:number) => {
         
            return (
              <Box className="cardMapContainer">
                <Box style={{ position: 'relative' }}>
                  <img src={card.image} style={{ width: '100%', height: '200px', objectFit: 'cover', borderTopLeftRadius: '16px', borderTopRightRadius: '16px', opacity: '.4' }} alt="Our Image" />
                  <Box style={{ position: 'absolute', bottom: '24px', left: '25px', width: '88%', justifyContent: 'space-between', display: 'flex' }}>
                    <Box>
                      <Typography className="cardmaintitle" variant='body1' style={{ fontSize: '12px' ,fontWeight: 400,color:'#FFFFFF', textTransform: 'uppercase', letterSpacing: '.5', opacity: '.8'}}>{card.category_name}</Typography>
                      <Box>
                        <Typography className="cardTitle">{card.name}</Typography>
                      </Box>
                    </Box>
                    <Box style={{ display: 'flex', alignItems: 'end' }} >
                      <Button startIcon={<PlayCircleFilledIcon />} variant='contained' size='small' style={{ color: 'white', textTransform: 'capitalize', backgroundColor: 'rgba(205, 205, 205, 0.5)', fontSize: '12px', height: '24px', width: '94px',borderRadius: '20px' }}>Preview</Button>
                    </Box>
                  </Box>
                </Box>
                <Box style={{ margin:'-5px 5px',padding: '10px 20px', backgroundColor: '#172130', borderBottomLeftRadius: '16px', borderBottomRightRadius: '16px' }}>
                  <Box style={{ display: 'flex', justifyContent: 'space-between',marginTop: 10 }}>
                    <Box style={{ display: 'flex', alignItems: "center" }}>
        
                      <LibraryBooksIcon style={{color: 'white',marginRight: 5, width: '20px'}} fontSize= 'small'/>
                    
                      <Typography className="cardinfo" style={{color:'#FFFFFF',fontSize: '13px'}}>{card.all_units_count} units</Typography>
                    </Box>
                    <Box style={{ display: 'flex', alignItems: "center" }} >
                      < ScheduleIcon style={{color: 'white',marginRight: 5, width: '20px'}}/>
                      <Typography  className="cardinfo" data-test-id="durationCard" style={{color:'#FFFFFF' ,fontSize: '13px'}}>{card.duration}</Typography>
                    </Box>
                    <Box style={{ display: 'flex', alignItems: "center" }} >
                    <PeopleOutlineOutlinedIcon style={{color: 'white',marginRight: 5, width: '20px'}} fontSize= 'small'/>
                      <Typography  className="cardinfo" style={{color:'#FFFFFF',fontSize: '13px'}}>{card.level}</Typography>
                    </Box>
                  </Box>
                  <Box style={{ marginTop: '20px',display:'flex',flexDirection: 'column',width: '100%',justifyContent: 'space-between'}}>
                    <div className="course_desc" style={{height:'100px',overflow: 'auto'}}>
                    <Typography style={{color:'rgba(255,255,255,.8)',fontSize: '14px', textAlign: 'justify'}}>{card.description}</Typography>
                    </div>
                    <div style={{marginTop:'20px'}}>
                    <Button className="enrollbtn" variant='contained' fullWidth style={{ color: 'white', 
                    background: 'linear-gradient(90deg, #6200EA 0%, #26C5F3 100%)',
                    borderRadius: '20px',bottom:'10px'
                    }}>Enroll</Button>
                    </div>
                  </Box>
                  <Box style={{ textAlign: 'center'}}>
                   
                  </Box>
                </Box>
              </Box>
            )

          })}
        </Box>
      </Box>
    
    )
  }

  stats =()=>{
    return(
      <Box className="statsContainer">
        <Box style={webStyle.rowDirection}>
          <h4 style={webStyle.subHeadingText}>Stats</h4>
          <Box style={{ textTransform: 'capitalize', background: "rgba(255, 255, 255, 0.1)", borderRadius: '20px' }}>
            <Button variant='outlined' style={{ borderRadius: '34px', textTransform: 'none', fontWeight: 300, color: 'white',fontSize: '14px' }}> This week</Button>
          </Box>
        </Box>
        <Box style={webStyle.statsBody}>
          <img src={graph} />
          <Typography style={{fontWeight:700,fontSize:'20px',color:'rgba(255, 255, 255, 1)',opacity: '30%'}}>Looking for your stats? They’re waiting to be discovered!</Typography>
          <Typography style={{fontWeight:300,fontSize:'20px',color:'rgba(255, 255, 255, 1)',opacity: '30%'}}> Begin using the platform, dive into courses, and engage with content to see your insights and progress unfold.</Typography>
        </Box>
      </Box>
    )
  }

  medEdJourney =()=>{
    return(
      <Box className="medMainContainer">
        <Box style={{...webStyle.rowDirection,padding:'10px 0 15px'}}>
          <h4 style={webStyle.subHeadingText}>MedEd Journeys</h4>
          <Box style={{ textTransform: 'capitalize', background: "rgba(255, 255, 255, 0.1)", borderRadius: '20px',justifyContent:'space-around',display:'flex',alignItems: 'center',marginRight:'4px'}}>
            <img src={filter} style={{paddingLeft:'10px'}} />
            <Button style={{  textTransform: 'capitalize', fontWeight: 300, color: 'white',fontSize: '14px'}}> Filter</Button>
          </Box>
        </Box>
     
         <Grid container spacing={2}>
              {this.state.medIDJourney?.map((item:any, index:any) => (
                <Grid item xs={6} sm={3} key={index} >
                  <Box className="medContainer" data-test-id="onCategory" onClick={()=>this.onClickCategory()}>
                    <div style={webStyle.imgIconBack}>
                      <img src={item.attributes.icon} 
                       />
                    </div>
                    <Typography className="medBodySubText">{item.attributes.name}</Typography>
                  </Box>
                </Grid>
              ))}
        </Grid>
      </Box>
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box style={webStyle.backgroundCLr}>
        <Header
          navigation={this.props.navigation}
          id={""}
        />
       
        {this.taskSelection()}
        {this.stats()}

        {this.cardContend()}
        {this.medEdJourney()}
        <Footer 
          navigation={undefined}
          id={''}
        />
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  subHeadingText: {
    fontSize: '20px',
    fontWeight: 700,
    color: 'rgba(255,255,255,1)',
    marginLeft:'4px'
  },
  statsBody: {
    display:'flex',
    flexDirection: 'column',
    alignItems: 'center',
    opacity: '30% !important'
  }as const,

  rowDirection: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px 0px',
    backgound: 'red'
  },
  medBody :{
    display: 'flex',
  },
  imgIconBack: {
    width: '48px',
    height: '48px',
    borderRadius: '24px',
    background: 'rgba(51, 65, 85, 1)',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    overflow: 'hidden'
  },
  backgroundCLr: {
   backgroundColor: 'rgb(28, 34, 53)' 
  },
  plusBtnStyle: {
    width: "32px !important",
    height: "32px !important",
    color: 'white',
    borderRadius: '50%',
    background:'linear-gradient(90deg, #6200EA 0%, #26C5F3 100%)',
    padding: 5
  },
  imgQ: {
    width: 14,
    height: 20
  },
  selectDateColor: {
    backgroundColor:'grey',
    fontWeight:400,
    borderRadius:10,
    padding:1
  }
};
// Customizable Area End
