import React, { CSSProperties } from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Typography,
  Menu,
  MenuItem,
  ListItemText,
  ThemeProvider,
  Grid,
} from "@material-ui/core";
import { createTheme, withStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { medEdsmall } from "./assets";

const StyledMenuItem = withStyles((theme) => ({
  "& .MuiMenu-list,& .MuiList-root,& .MuiMenu-list": {
    paddingBottom: "0px !important",
    paddingTop: "0px !important",
  },
  root: {
    "&:focus": {
      background:
        "linear-gradient(76.8deg, rgb(121, 45, 129) 3.6%, rgb(36, 31, 98) 90.4%)",

      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
        padding: 5,
        fontWeight: 700,
      },
    },
    "&:hover": {
      background:
        "linear-gradient(76.8deg, rgb(121, 45, 129) 3.6%, rgb(36, 31, 98) 90.4%)",
    },

    padding: "15px 30px !important",
    backgroundColor: "rgba(30, 41, 59, 1)",
  },
  "&:paper": {
    // Override styles for MuiPaper
    opacity: 1,
    transform: "none",
    transition:
      "opacity 217ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 145ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    top: 100,
    left: 197,
    transformOrigin: "66.5px 0px 0px",
  },
}))(MenuItem);

const StyledMenu = withStyles({
  paper: {
    opacity: 1,
    borderRadius: 20,
    backgroundColor: "rgba(30, 41, 59, 1)",
    transform: "none",
    transition:
      "opacity 217ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 145ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    top: "100px !important",
    left: "300px !important",
    transformOrigin: "66.5px 0px 0px",
    color: "white", // Example override for text color
  },
  "& .MuiMenuItem-root": {
    "&:hover": {
      backgroundColor: "black !important", // Change hover background color
    },
  },
})((props: any) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const theme1 = createTheme({
  overrides: {
    MuiList: {
      root: {
        padding: "0px !important", // override padding for MuiList
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: "blue", // set background color for MuiPaper
        top: "100px",
      },
    },
  },
});
// Customizable Area End

import Catalogue1Controller, { Props } from "./Catalogue1Controller";
import "./Header.css";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class Header extends Catalogue1Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  handleClick = (event: any, menuType: string) => {
    if (menuType === "home") {
      this.setState({ anchorElHome: event.currentTarget, selectedMenu: 1 });
    } else if (menuType === "tools") {
      this.setState({ anchorElTools: event.currentTarget, selectedMenu: 2 });
    } else if (menuType === "services") {
      this.setState({ anchorElServices: event.currentTarget, selectedMenu: 3 });
    } else if (menuType === "help") {
      this.setState({ anchorElhelp: event.currentTarget, selectedMenu: 4 });
    }
  };

  handleClose = (menuType: any) => {
    if (menuType === "tools") {
      this.setState({ anchorElTools: null });
    } else if (menuType === "services") {
      this.setState({ anchorElServices: null });
    }
  };

  getMenuStyle = (isSelected: boolean, anchorEl: any): CSSProperties => {
    return {
      cursor: "pointer",
      color: isSelected ? "white" : "rgba(255, 255, 255, 1)",
      textAlign: "center" as "center",
      fontWeight: isSelected ? 600 : this.getFontWeight(anchorEl),
      marginLeft: "15px",
    };
  };
  getFontWeight = (isActive: any) => (isActive ? 500 : 300);

  displayMenu = () => {
    const {
      anchorElHome,
      anchorElTools,
      anchorElServices,
      anchorElhelp,
      selectedMenu,
    } = this.state;
    return (
      <div style={{ display: "flex" }}>
        <div
          style={{ position: "relative" }}
          data-test-id="onClickHome"
          onClick={() => this.onClickHome()}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              onClick={(event) => this.handleClick(event, "home")}
              data-test-id="handleClickHome"
              className="displayMenuText"
              style={this.getMenuStyle(selectedMenu == 1, anchorElHome)}
            >
              Home
            </Typography>
            {selectedMenu == 1 ? (
              <p
                style={{
                  width: "5px",
                  height: "5px",
                  borderRadius: "50%",
                  backgroundColor:
                    anchorElHome || selectedMenu == 1
                      ? "white"
                      : "rgba(255, 255, 255, 0.4)",
                  color: "white",
                  marginLeft: "3px",
                }}
              />
            ) : null}
          </Box>
        </div>
        <div>
          <Typography
            onClick={(event) => this.handleClick(event, "tools")}
            data-test-id="handleClickTools"
            className="displayMenuText"
            style={{
              ...this.getMenuStyle(selectedMenu == 2, anchorElHome),
              marginLeft: "auto",
            }}
          >
            My Tools
          </Typography>
          {selectedMenu == 2 ? (
            <p
              style={{
                width: "5px",
                height: "5px",
                borderRadius: "50%",
                backgroundColor:
                  anchorElTools || selectedMenu == 2
                    ? "white"
                    : "rgba(255, 255, 255, 0.4)",
                color: "white",
                marginLeft: "38px",
              }}
            />
          ) : null}
          <StyledMenu
            id="customized-menu-tools"
            keepMounted
            anchorEl={anchorElTools}
            open={Boolean(anchorElTools)}
            data-test-id="handleCloseTools"
            onClose={() => this.handleClose("tools")}
          >
            <StyledMenuItem
              data-test-id="handleScheduleClick"
              onClick={() => {
                this.handleScheduleClick();
              }}
            >
              <ListItemText primary="My Schedule" />
            </StyledMenuItem>
            <StyledMenuItem>
              <ListItemText primary="My Tasks" />
            </StyledMenuItem>
            <StyledMenuItem>
              <ListItemText primary="My Files" />
            </StyledMenuItem>
          </StyledMenu>
        </div>

        <div>
          <Typography
            onClick={(event) => this.handleClick(event, "services")}
            data-test-id="handleClickServices"
            className="displayMenuText"
            style={this.getMenuStyle(selectedMenu == 3, anchorElHome)}
          >
            My Services
          </Typography>
          {selectedMenu == 3 ? (
            <p
              style={{
                width: "5px",
                height: "5px",
                borderRadius: "50%",
                backgroundColor:
                  anchorElServices || selectedMenu == 3
                    ? "white"
                    : "rgba(255, 255, 255, 0.4)",
                color: "white",
                marginLeft: "50px",
              }}
            />
          ) : null}

          <StyledMenu
            id="customized-menu-services"
            keepMounted
            anchorEl={anchorElServices}
            open={Boolean(anchorElServices)}
            data-test-id="handleCloseServices"
            onClose={() => this.handleClose("services")}
          >
            <StyledMenuItem
              data-test-id="onMenuItemClick"
              onClick={() => {
                this.onMenuItemClick();
              }}
            >
              <ListItemText primary="Knowledge & Training Center" />
            </StyledMenuItem>
            <StyledMenuItem>
              <ListItemText primary="Healthcare VR/AR & Gaming" />
            </StyledMenuItem>
            <StyledMenuItem>
              <ListItemText primary="Marketplace" />
            </StyledMenuItem>
            <StyledMenuItem>
              <ListItemText primary="Healthcare Research" />
            </StyledMenuItem>
            <StyledMenuItem>
              <ListItemText primary="Healthcare Innovation & Enterpreneurship" />
            </StyledMenuItem>
          </StyledMenu>
        </div>
        <div>
          <Typography
            onClick={(event) => this.handleClick(event, "help")}
            data-test-id="handleClickHelp"
            className="displayMenuText"
            style={{
              cursor: "pointer",
              color: "rgba(255, 255, 255, 1)",
              textAlign: "center",
              fontWeight: this.getFontWeight(anchorElhelp),
              marginLeft: "25px",
            }}
          >
            Help Center
          </Typography>
          {selectedMenu == 4 ? (
            <p
              style={{
                width: "5px",
                height: "5px",
                borderRadius: "50%",
                backgroundColor: anchorElhelp
                  ? "white"
                  : "rgba(255, 255, 255, 0.4)",
                color: "white",
                marginLeft: "50px",
              }}
            />
          ) : null}
        </div>
      </div>
    );
  };

  renderNav = () => {
    return (
      <Grid container xl={12} className="headerAll" data-testid="nav">
        <Box className="headerMain">
          <Box className="logoContainer">
            <img className="logoTop" src={medEdsmall} alt="medEd_logo" />
          </Box>
          <Grid item xl={12} sm={6} className="displayMenu">
            {this.displayMenu()}
          </Grid>

          <Grid
            item
            xl={12}
            sm={6}
            className="searchContainer"
            data-testid="auth-wrapper"
          >
            <div className="searchBtn">
              <SearchIcon />
            </div>
            &nbsp;&nbsp;
            <Box className="showProfile">
              <Box className="profileLetter">
                {this.state.userName?.charAt(0)}
              </Box>
              <div className="textContainer">
                <span className="usrNameTxt" data-testid="signupBtn">
                  {this.state.userName}
                </span>
                <span className="subTextProfile">Medicine</span>
              </div>
              <KeyboardArrowDownIcon />
            </Box>
          </Grid>
        </Box>
      </Grid>
    );
  };

  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <Container maxWidth="xl" className="container-header">
        {this.renderNav()}
      </Container>
      // Customizable Area End
    );
  }
}
